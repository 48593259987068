html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
	font-family: courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14233B;
  color: white;
}

pre {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 0;
}

#root {
  width: 100%;
  height: 100%;
}

#root > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}