
.terminal-output {
  overflow-y: scroll;
}

.terminal-input {
  margin-top: 1%;
  height: 83%;
}

.terminal-output .container {
  min-width: 100%;
  padding-bottom: 1.1%;
}

.layout-splitter {
  background-color: white !important;
  border-radius: 5rem;
}