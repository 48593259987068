.stream {
    max-width: 5ch !important;
    font-weight: bold;
    text-align: right;
    color: rgb(10, 235, 235);

}

.out {
    color: rgb(255, 82, 154);
}

.error {
    color: rgb(255, 190, 10);
}

.cell {
  border: 1px solid transparent;
}

.cell:hover {
  border: 1px solid #000000;
}

.cell-dark {
    background-color: rgb(19, 29, 49);
}

.command {
    background-color: inherit;
    padding: 0;
    color: rgb(106, 255, 101);
}