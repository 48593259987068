form,
input,
.form-control,
.input-group,
.input-group-text {
    height: 100%;
    background-color: inherit !important;
    color: inherit !important;
}

.form-control,
.input-group-text {
    border: none;
    margin-right: 0.5%;
}

.input-group-text {
    align-items: unset;
}
