.settings-panel {
  text-align: center;
}

.settings-panel .btn {
  background-color: inherit;
  opacity: 0.5;
  border-color: transparent
}

.settings-panel .btn:hover {
  opacity: 1;
  transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -webkit-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
}

.settings-panel .btn:active {
  background-color: inherit !important;
}
